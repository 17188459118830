import {
	title,
	type Build as BuildType,
	type BuildList,
	type BuildStorePayload,
	type BuildUpdatePayload,
} from '@/interfaces/Build'
import BuildsApi from '../api/BuildsApi'
import BuildListState from '../states/BuildListState'
import { Model } from './Model'

export class BuildModel extends Model<
	BuildsApi,
	BuildListState,
	BuildType,
	BuildList,
	BuildStorePayload,
	BuildUpdatePayload
> {
	api = new BuildsApi()
	useListState = () => new BuildListState()
	title = title
}

const Build = new BuildModel()
export default Build
