import type { Deployment, DeploymentList } from '@/interfaces/Deployment'
import DeploymentsApi from '../api/DeploymentsApi'
import ListState from './ListState'

export default class DeploymentListState extends ListState<
	DeploymentsApi,
	Deployment,
	DeploymentList
> {
	api = new DeploymentsApi()
}
