<template>
	<div class="code-breakdown">
		<h2>Generated code</h2>
		<ul>
			<li>{{ schema.length }} Modules</li>
			<ul>
				<li>Frontend</li>
				<ul>
					<li>{{ props.schema.length + relationWidgetsCount }} Data Tables</li>
					<li>{{ relationWidgetsCount }} Relation Widgets</li>
					<li>{{ props.schema.length + relationWidgetsCount }} Forms</li>
					<li>{{ relationWidgetsCount }} Popovers</li>
				</ul>
				<li>Backend</li>
				<ul>
					<li>{{ tablesCount }} Database Tables</li>
					<li>{{ schema.length }} Models</li>
					<li>{{ relationsCount }} Relations</li>
					<li>{{ schema.length }} CRUD Controllers</li>
				</ul>
			</ul>
			<li>Kubernetes Config</li>
			<li>Docker Config</li>
			<li>CI/CD Config</li>
		</ul>
	</div>
</template>

<script setup lang="ts">
import type { EnrichedModuleSchema } from '@/interfaces/schemas/enrichedModuleSchema'
import { computed } from 'vue'

const props = defineProps<{
	schema: EnrichedModuleSchema[]
}>()

const tablesCount = computed(() => {
	let count = 0
	count += props.schema.length
	for (const module of props.schema) {
		for (const relationName in module.model.relations) {
			const relation = module.model.relations[relationName]
			if (
				relation.relationType === 'BelongsToMany' &&
				relation.name.localeCompare(relation.inverse) < 0
			) {
				count += 1
			}
		}
	}
	return count
})
const relationWidgetsCount = computed(() => {
	let count = 0
	for (const module of props.schema) {
		for (const relationName in module.model.relations) {
			const relation = module.model.relations[relationName]
			if (relation.relationType === 'BelongsToMany' || relation.relationType === 'HasMany') {
				count += 1
			}
		}
	}
	return count
})
const relationsCount = computed(() => {
	let count = 0
	for (const module of props.schema) {
		count += Object.keys(module.model.relations).length
	}
	return count
})
</script>

<style scoped lang="scss">
ul {
	list-style-position: inside;
	padding-left: 15px;
}
</style>
