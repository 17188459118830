<template>
	<div class="register">
		<Card
			v-if="auth.userReturned"
			class="register__card">
			<template #title>Register</template>
			<template #content>
				<form>
					<label for="email">Email</label>
					<InputText
						id="email"
						v-model="form.email"
						@keyup.enter="submit" />
					<label for="password">Password</label>
					<InputText
						id="password"
						v-model="form.password"
						type="password"
						@keyup.enter="submit" />
					<label for="password_confirmation">Password Confirmation</label>
					<InputText
						id="password_confirmation"
						v-model="form.password_confirmation"
						type="password"
						@keyup.enter="submit" />
					<Button
						class="register__register-button"
						:loading="loading"
						@click="submit"
						@submit.stop>
						Register
					</Button>
					<Divider />
					<Button
						as="router-link"
						:disabled="loading"
						outlined
						to="/login">
						Login
					</Button>
				</form>
			</template>
		</Card>
		<ProgressSpinner v-else />
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useAuthStore } from '@/stores/Auth'
import { useRouter } from 'vue-router'
import ProgressSpinner from 'primevue/progressspinner'
import Button from 'primevue/button'
import Card from 'primevue/card'
import InputText from 'primevue/inputtext'
import Divider from 'primevue/divider'

const form = ref({
	email: '',
	password: '',
	password_confirmation: '',
})
const loading = ref(false)

const router = useRouter()
const auth = useAuthStore()

async function submit() {
	loading.value = true
	try {
		await auth.register(form.value)
		await auth.getUser()
	} finally {
		loading.value = false
	}
	router.push('/')
}
</script>

<style scoped lang="scss">
.register {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100%;

	.register__card {
		width: 500px;

		&:deep(.p-card-content) {
			form {
				gap: 10px;
				display: flex;
				flex-direction: column;

				.register__register-button {
					margin-top: 16px;
				}
			}
		}
	}
}
</style>
