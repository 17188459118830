export function pascalToSnakeCase(str: string) {
	return str
		.replace(/[\w]([A-Z])/g, function (m) {
			return m[0] + '_' + m[1]
		})
		.toLowerCase()
}

export function camelToSnakeCase(str: string) {
	return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
}

export function pascalToCamelCase(str: string) {
	return str.charAt(0).toLowerCase() + str.slice(1)
}

export function camelToPascalCase(str: string) {
	return str.charAt(0).toUpperCase() + str.slice(1)
}

export function snakeToPascalCase(str: string) {
	return str
		.split('_')
		.map((word) => camelToPascalCase(word))
		.join('')
}

export function snakeToCamelCase(str: string) {
	return str
		.split('_')
		.map((word, index) => {
			if (index === 0) {
				return word
			}
			return camelToPascalCase(word)
		})
		.join('')
}

export function snakeCaseToLabel(str: string) {
	return str
		.split('_')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ')
}

export function camelCaseToLabel(str: string) {
	return snakeCaseToLabel(camelToSnakeCase(str))
}

export function pascalCaseToLabel(str: string) {
	return snakeCaseToLabel(pascalToSnakeCase(str))
}

export function pascalCaseToKebab(str: string) {
	return pascalToSnakeCase(str).replace(/_/g, '-')
}

export function camelCaseToKebab(str: string) {
	return camelToSnakeCase(str).replace(/_/g, '-')
}

export function snakeCaseToKebab(str: string) {
	return str.replace(/_/g, '-')
}

export function kebabToSnakeCase(str: string) {
	return str.replace(/-/g, '_')
}

export function kebabToCamelCase(str: string) {
	return snakeToCamelCase(kebabToSnakeCase(str))
}

export function kebabToPascalCase(str: string) {
	return snakeToPascalCase(kebabToSnakeCase(str))
}

export function kebabCaseToLabel(str: string) {
	return snakeCaseToLabel(kebabToSnakeCase(str))
}

export function isValidPascalCase(str: string) {
	const regex = /^[A-Z0-9][a-zA-Z0-9]*$/
	return regex.test(str)
}

export function isValidCamelCase(str: string) {
	const regex = /^[a-z0-9][a-zA-Z0-9]*$/
	return regex.test(str)
}

export function isValidSnakeCase(str: string) {
	const regex = /^[a-z0-9]+(_[a-z0-9]+)*$/
	return regex.test(str)
}

export function indentMultilineString(inputString: string, numberOfTabs: number) {
	const tabString = '\t'.repeat(numberOfTabs)
	const lines = inputString.split('\n')
	const indentedLines = lines.map((line: string) => tabString + line)
	return indentedLines.join('\n')
}
