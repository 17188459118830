<template>
	<svg
		width="89.105095"
		height="89"
		viewBox="0 0 89.105095 89"
		fill="none"
		version="1.1">
		<circle
			cx="14"
			cy="14"
			r="11.5"
			stroke="#000000"
			stroke-width="5" />
		<circle
			cx="14"
			cy="44"
			r="11.5"
			stroke="#000000"
			stroke-width="5" />
		<rect
			x="30.91856"
			y="1.5525182"
			width="44"
			height="6"
			transform="rotate(30)"
			fill="#000000" />
		<circle
			cx="-75.105003"
			cy="14"
			r="11.5"
			transform="scale(-1,1)"
			stroke="#000000"
			stroke-width="5" />
		<circle
			cx="-75.105003"
			cy="44"
			r="11.5"
			transform="scale(-1,1)"
			stroke="#000000"
			stroke-width="5" />
		<rect
			width="43.999985"
			height="5.9999981"
			transform="matrix(-0.8660253,0.50000017,0.50000017,0.8660253,0,0)"
			fill="#000000"
			x="-46.248711"
			y="46.105076" />
		<circle
			cx="14"
			cy="-75"
			r="11.5"
			transform="scale(1,-1)"
			stroke="#000000"
			stroke-width="5" />
		<rect
			width="43.999985"
			height="5.9999981"
			transform="matrix(0.8660253,-0.50000017,-0.50000017,-0.8660253,0,0)"
			fill="#000000"
			x="-14.081455"
			y="-76.389763" />
		<circle
			cx="-75.105095"
			cy="-75"
			r="11.5"
			transform="scale(-1)"
			stroke="#000000"
			stroke-width="5" />
		<rect
			x="-91.248718"
			y="-31.837221"
			width="44"
			height="6"
			transform="rotate(-150)"
			fill="#000000" />
		<rect
			x="23"
			y="42"
			width="41"
			height="6"
			fill="#000000" />
	</svg>
</template>
