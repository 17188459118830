<template>
	<Header :title="isEdit ? 'Edit Build' : 'Create Build'" />
	<ProgressBar
		v-if="loaders.size > 0"
		mode="indeterminate"
		style="height: 6px" />
	<Container class="edit">
		<Form
			:id="route.params.id as string"
			:is-edit="isEdit"
			@start-loading="loaders.add('form')"
			@stop-loading="loaders.delete('form')" />
		<Deployments
			v-if="isEdit"
			:build-id="route.params.id as string"
			@start-loading="loaders.add('Deployment')"
			@stop-loading="loaders.delete('Deployment')" />
	</Container>
</template>

<script setup lang="ts">
import { reactive, computed } from 'vue'
import { useRoute } from 'vue-router'
import Header from './components/Header.vue'
import Form from './components/Form.vue'
import ProgressBar from 'primevue/progressbar'
import Container from '@/components/Container.vue'
import Deployments from './components/Deployments.vue'

const route = useRoute()
const isEdit = computed(() => route.name === 'builds-edit')
const loaders = reactive(new Set<string>())
</script>

<style lang="scss" scoped>
.edit {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
	gap: 11px;
	justify-items: center;
	align-items: start;
}
</style>
