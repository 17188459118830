import type {
	RepositoryConfig,
	RepositoryConfigList,
	RepositoryConfigStorePayload,
	RepositoryConfigUpdatePayload,
} from '@/interfaces/RepositoryConfig'
import Api from '@/helpers/api/Api'

export default class RepositoryConfigsApi extends Api<
	RepositoryConfig,
	RepositoryConfigList,
	RepositoryConfigStorePayload,
	RepositoryConfigUpdatePayload
> {
	route = 'repository-configs'
}
