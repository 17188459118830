<template>
	<svg
		width="90"
		height="89"
		viewBox="0 0 90 89"
		fill="none"
		version="1.1">
		<circle
			cx="14"
			cy="45"
			r="11.5"
			stroke="#000000"
			stroke-width="5" />
		<circle
			cx="62"
			cy="14"
			r="11.5"
			stroke="#000000"
			stroke-width="5" />
		<circle
			cx="62"
			cy="75"
			r="11.5"
			stroke="#000000"
			stroke-width="5" />
		<circle
			cx="76"
			cy="45"
			r="11.5"
			stroke="#000000"
			stroke-width="5" />
		<rect
			x="23"
			y="42"
			width="44"
			height="6"
			fill="#000000" />
		<rect
			x="-4.6124964"
			y="44.80888"
			width="37.484798"
			height="6"
			transform="rotate(-35.1122)"
			fill="#000000" />
		<rect
			width="37.484779"
			height="5.9999971"
			transform="matrix(0.81802739,0.57517927,0.57517927,-0.81802739,0,0)"
			fill="#000000"
			x="47.277885"
			y="-28.990276" />
	</svg>
</template>
