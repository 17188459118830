<template>
	<Header
		:list-state="listState"
		search
		title="CodeDeliveries" />
	<Container>
		<ApiTable
			:list-state="listState"
			@row-click="openDetails">
			<Column
				field="status"
				header="Status" />
			<Column
				field="failure_reason"
				header="Failure Reason" />
			<Column
				field="impact_level"
				header="Impact Level" />
			<Column header="">
				<template #body="slotProps">
					<ApiTableRemoveButton :item="slotProps.data" />
				</template>
			</Column>
		</ApiTable>
	</Container>
</template>

<script setup lang="ts">
import Header from './components/Header.vue'
import { onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import ApiTable from '@/components/Table/ApiTable.vue'
import Column from 'primevue/column'
import Container from '@/components/Container.vue'
import type { CodeDelivery } from '@/interfaces/CodeDelivery'
import CodeDeliveryModel from '@/helpers/models/CodeDelivery'
import ApiTableRemoveButton from '@/components/Table/ApiTableRemoveButton.vue'

const listState = CodeDeliveryModel.useListState()
const router = useRouter()

onBeforeMount(() => {
	listState.getList()
})

function openDetails(item: { data: CodeDelivery }) {
	router.push({ name: 'code-deliveries-edit', params: { id: item.data.id } })
}
</script>
