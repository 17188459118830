import {
	title,
	type CodeDelivery as CodeDeliveryType,
	type CodeDeliveryList,
	type CodeDeliveryStorePayload,
	type CodeDeliveryUpdatePayload,
} from '@/interfaces/CodeDelivery'
import CodeDeliveriesApi from '../api/CodeDeliveriesApi'
import CodeDeliveryListState from '../states/CodeDeliveryListState'
import { Model } from './Model'

export class CodeDeliveryModel extends Model<
	CodeDeliveriesApi,
	CodeDeliveryListState,
	CodeDeliveryType,
	CodeDeliveryList,
	CodeDeliveryStorePayload,
	CodeDeliveryUpdatePayload
> {
	api = new CodeDeliveriesApi()
	useListState = () => new CodeDeliveryListState()
	title = title
}

const CodeDelivery = new CodeDeliveryModel()
export default CodeDelivery
