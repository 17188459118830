import type {
	Deployment,
	DeploymentList,
	DeploymentStorePayload,
	DeploymentUpdatePayload,
} from '@/interfaces/Deployment'
import Api from '@/helpers/api/Api'

export default class DeploymentsApi extends Api<
	Deployment,
	DeploymentList,
	DeploymentStorePayload,
	DeploymentUpdatePayload
> {
	route = 'deployments'
}
