<template>
	<Header
		:list-state="listState"
		search
		title="Apps" />
	<Container>
		<ApiTable
			:list-state="listState"
			@row-click="openDetails">
			<Column
				field="name"
				header="Name" />
			<Column
				:style="{ maxWidth: '72px', width: '72px' }"
				header="">
				<template #body="slotProps">
					<ApiTableRemoveButton :item="slotProps.data" />
				</template>
			</Column>
		</ApiTable>
	</Container>
	<Form
		as-dialog
		should-redirect
		:visible="appFormVisible"
		@close="router.push({ name: 'apps-list' })" />
</template>

<script setup lang="ts">
import Header from './components/Header.vue'
import { computed, onBeforeMount } from 'vue'
import ApiTable from '@/components/Table/ApiTable.vue'
import AppModel from '@/helpers/models/App'
import Column from 'primevue/column'
import type { App } from '@/interfaces/App'
import { useRoute, useRouter } from 'vue-router'
import ApiTableRemoveButton from '@/components/Table/ApiTableRemoveButton.vue'
import Container from '@/components/Container.vue'
import Form from './components/Form.vue'

const listState = AppModel.useListState()
const router = useRouter()
const route = useRoute()

onBeforeMount(() => {
	listState.getList()
})

const appFormVisible = computed(() => route.name === 'apps-create')

function openDetails(item: { data: App }) {
	router.push({ name: 'apps-details', params: { id: item.data.id } })
}
</script>
