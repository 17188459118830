import { useAuthStore } from '@/stores/Auth'
import type { RouteLocationNormalizedLoaded } from 'vue-router'

export default async (to: RouteLocationNormalizedLoaded) => {
	const auth = useAuthStore()

	if (to.path !== '/login' && to.path !== '/register' && to.path !== '/verify-email') {
		auth.setLastRoute(to.fullPath)
	}
	if (!auth.user) {
		await auth.getUser()
		if (auth.user) {
			if (to.path === '/login' || to.path === '/register') {
				return '/'
			}
			return to
		}

		return '/login'
	}

	if (!auth?.user.verified && to.path !== '/verify-email') {
		return '/verify-email'
	}

	if (auth?.user.verified && to.path === '/verify-email') {
		return '/'
	}
}
