import type {
	Purchase,
	PurchaseList,
	PurchaseStorePayload,
	PurchaseUpdatePayload,
} from '@/interfaces/Purchase'
import Api from '@/helpers/api/Api'

export default class PurchasesApi extends Api<
	Purchase,
	PurchaseList,
	PurchaseStorePayload,
	PurchaseUpdatePayload
> {
	route = 'purchases'
}
