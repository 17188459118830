import {
	title,
	type Deployment as DeploymentType,
	type DeploymentList,
	type DeploymentStorePayload,
	type DeploymentUpdatePayload,
} from '@/interfaces/Deployment'
import DeploymentsApi from '../api/DeploymentsApi'
import DeploymentListState from '../states/DeploymentListState'
import { Model } from './Model'

export class DeploymentModel extends Model<
	DeploymentsApi,
	DeploymentListState,
	DeploymentType,
	DeploymentList,
	DeploymentStorePayload,
	DeploymentUpdatePayload
> {
	api = new DeploymentsApi()
	useListState = () => new DeploymentListState()
	title = title
}

const Deployment = new DeploymentModel()
export default Deployment
