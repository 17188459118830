import {
	title,
	type Purchase as PurchaseType,
	type PurchaseList,
	type PurchaseStorePayload,
	type PurchaseUpdatePayload,
} from '@/interfaces/Purchase'
import PurchasesApi from '../api/PurchasesApi'
import PurchaseListState from '../states/PurchaseListState'
import { Model } from './Model'

export class PurchaseModel extends Model<
	PurchasesApi,
	PurchaseListState,
	PurchaseType,
	PurchaseList,
	PurchaseStorePayload,
	PurchaseUpdatePayload
> {
	api = new PurchasesApi()
	useListState = () => new PurchaseListState()
	title = title
}

const Purchase = new PurchaseModel()
export default Purchase
