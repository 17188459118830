import type { LaravelPaginationResponse } from './Laravel'
import type { Title } from './Model'

export interface ServerStorePayload {
	ip: string
}

export interface ServerUpdatePayload {
	ip: string
}

export interface Server {
	id: string | number
	ip: string
	created_at: string
	updated_at: string
}

export interface ServerList extends LaravelPaginationResponse<Server> {}

export const title: Title = 'ip'
