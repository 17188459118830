import type { LaravelPaginationResponse } from './Laravel'
import type { Title } from './Model'

export interface PurchaseStorePayload {
	stripe_purchase_id: string
	status: string
	app_id?: number
}

export interface PurchaseUpdatePayload {
	stripe_purchase_id: string
	status?: string
	app_id?: number
}

export interface Purchase {
	id: string | number
	stripe_purchase_id: string
	status: string
	app_id?: number
	created_at: string
	updated_at: string
}

export interface PurchaseList extends LaravelPaginationResponse<Purchase> {}

export const title: Title = 'stripe_purchase_id'
