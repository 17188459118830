import axios, { type AxiosResponse } from 'axios'

const apiUrl = import.meta.env.VITE_API_URL

export default class StripeApi {
	route = 'stripe'

	async checkout(params: {
		price_id: string
		app_id: string
	}): Promise<AxiosResponse<{ clientSecret: string }>> {
		return await axios.post(`${apiUrl}${this.route}/checkout`, params)
	}

	async completeCheckout(params: {
		session_id: string
	}): Promise<AxiosResponse<{ status: string; customer_email: string }>> {
		return await axios.post(`${apiUrl}${this.route}/complete-checkout`, params)
	}
}
