import type { EnrichedColumnSchema } from '@/interfaces/schemas/enrichedModuleSchema'

export const typeMap: Record<EnrichedColumnSchema['type'], string> = {
	// bigIncrements: 'number',
	bigInteger: 'number',
	binary: 'string',
	boolean: 'boolean',
	char: 'string',
	dateTime: 'string',
	date: 'string',
	decimal: 'number',
	double: 'number',
	enum: 'string',
	float: 'number',
	foreignId: 'number',
	// foreignIdFor: 'number',
	// foreignUlid: 'string',
	// foreignUuid: 'string',
	// id: 'number',
	// increments: 'number',
	integer: 'number',
	json: 'string',
	longText: 'string',
	// mediumIncrements: 'number',
	mediumInteger: 'number',
	mediumText: 'string',
	// morphs: 'string',
	// nullableMorphs: 'string',
	// nullableTimestamps: 'string',
	// nullableUuidMorphs: 'string',
	// smallIncrements: 'number',
	smallInteger: 'number',
	softDeletes: 'string',
	string: 'string',
	text: 'string',
	time: 'string',
	timestamp: 'string',
	// tinyIncrements: 'number',
	tinyInteger: 'number',
	tinyText: 'string',
	// unsignedBigInteger: 'number',
	// unsignedDecimal: 'number',
	// unsignedInteger: 'number',
	// unsignedMediumInteger: 'number',
	// unsignedSmallInteger: 'number',
	// unsignedTinyInteger: 'number',
	// uuidMorphs: 'string',
	ulid: 'string',
	uuid: 'string',
	year: 'string',
}

export const iconMap: Record<keyof typeof typeMap, string> = {
	// bigIncrements: 'fa-hashtag',
	bigInteger: 'fa-hashtag',
	binary: 'fa-file',
	boolean: 'fa-square-check',
	char: 'fa-font',
	dateTime: 'fa-calendar-clock',
	date: 'fa-calendar',
	decimal: 'fa-hashtag',
	double: 'fa-hashtag',
	enum: 'fa-list-ul',
	float: 'fa-hashtag',
	foreignId: 'fa-hashtag',
	// foreignIdFor: 'fa-hashtag',
	// foreignUlid: 'fa-font',
	// foreignUuid: 'fa-font',
	// id: 'fa-hashtag',
	// increments: 'fa-hashtag',
	integer: 'fa-hashtag',
	json: 'fa-brackets-curly',
	longText: 'fa-font',
	// mediumIncrements: 'fa-hashtag',
	mediumInteger: 'fa-hashtag',
	mediumText: 'fa-font',
	// morphs: 'fa-font',
	// nullableMorphs: 'fa-font',
	// nullableTimestamps: 'fa-calendar-clock',
	// nullableUuidMorphs: 'fa-font',
	// smallIncrements: 'fa-hashtag',
	smallInteger: 'fa-hashtag',
	softDeletes: 'fa-calendar',
	string: 'fa-font',
	text: 'fa-font',
	time: 'fa-clock',
	timestamp: 'fa-calendar-clock',
	// tinyIncrements: 'fa-hashtag',
	tinyInteger: 'fa-hashtag',
	tinyText: 'fa-font',
	// unsignedBigInteger: 'fa-hashtag',
	// unsignedDecimal: 'fa-hashtag',
	// unsignedInteger: 'fa-hashtag',
	// unsignedMediumInteger: 'fa-hashtag',
	// unsignedSmallInteger: 'fa-hashtag',
	// unsignedTinyInteger: 'fa-hashtag',
	// uuidMorphs: 'fa-font',
	ulid: 'fa-font',
	uuid: 'fa-font',
	year: 'fa-calendar',
}
