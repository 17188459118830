import type { Build } from './Build'
import type { LaravelPaginationResponse } from './Laravel'
import type { Title } from './Model'
import type { Purchase } from './Purchase'
import type { RepositoryConfig } from './RepositoryConfig'
import type { User } from './User'

export interface AppStorePayload {
	name: string
	latest_query?: string
}

export interface AppUpdatePayload {
	name: string
	latest_query?: string
}

export interface App {
	id: string | number
	name: string
	latest_query: string
	created_at: string
	updated_at: string
	builds?: Build[]
	creator?: User
	repository_config?: RepositoryConfig
	purchases?: Purchase[]
}

export interface AppList extends LaravelPaginationResponse<App> {}

export const title: Title = 'name'
