<template>
	<div class="relation__diagram-container">
		<div class="relation__model-container">
			<i :class="'relation__model-icon ' + props.moduleSchema.icon"></i>
			<div class="relation__model-name">
				{{ camelCaseToLabel(props.moduleSchema.displayName) }}
			</div>
		</div>
		<div class="relation__arrows-container">
			<div class="relation__relation-name-container">
				<a
					class="relation__relation-type"
					target="_blank"
					:href="relationTypeDocs[props.schema.relationType]">
					{{ relationTypeMap[props.schema.relationType] }}
				</a>
				<div class="relation__relation-name">{{ props.schema.displayName }}</div>
			</div>
			<div class="relation__relation-arrow">
				<div class="relation__relation-arrow-line"></div>
				<i
					v-if="
						props.schema.relationType === 'HasMany' || props.schema.relationType === 'BelongsToMany'
					"
					class="relation__relation-arrow-tip fal fa-split"></i>
				<i
					v-else
					class="relation__relation-arrow-tip fal fa-arrow-right-long"></i>
			</div>
			<div
				v-if="props.inverseRelationSchema"
				class="relation__relation-arrow relation__relation-arrow--inverse">
				<i
					v-if="props.schema.relationType === 'HasMany' || props.schema.relationType === 'HasOne'"
					class="relation__relation-arrow-tip relation__relation-arrow-tip--inverse fal fa-arrow-right-long"></i>
				<i
					v-else
					class="relation__relation-arrow-tip relation__relation-arrow-tip--inverse fal fa-split"></i>
				<div class="relation__relation-arrow-line"></div>
			</div>
			<div
				v-if="props.inverseRelationSchema"
				class="relation__relation-name-container relation__relation-name-container--inverse">
				<a
					class="relation__relation-type"
					target="_blank"
					:href="relationTypeDocs[props.schema.relationType]">
					{{ relationTypeMap[props.inverseRelationSchema.relationType] }}
				</a>
				<div class="relation__relation-name">{{ props.inverseRelationSchema.displayName }}</div>
			</div>
		</div>
		<div class="relation__model-container">
			<i :class="'relation__model-icon ' + props.inverseModuleSchema.icon"></i>
			<div class="relation__model-name">
				{{ camelCaseToLabel(props.inverseModuleSchema.displayName) }}
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import type {
	EnrichedRelationSchema,
	EnrichedModuleSchema,
} from '@/interfaces/schemas/enrichedModuleSchema'
import { camelCaseToLabel } from '@/helpers/strings'

const relationTypeMap = {
	BelongsTo: 'belongs to',
	HasMany: 'has many',
	BelongsToMany: 'belongs to many',
	HasOne: 'has one',
}

const relationTypeDocs = {
	BelongsTo: 'https://laravel.com/docs/11.x/eloquent-relationships#one-to-many-inverse',
	HasMany: 'https://laravel.com/docs/11.x/eloquent-relationships#one-to-many',
	BelongsToMany: 'https://laravel.com/docs/11.x/eloquent-relationships#many-to-many',
	HasOne: 'https://laravel.com/docs/11.x/eloquent-relationships#one-to-one',
}

const props = defineProps<{
	schema: EnrichedRelationSchema
	inverseModuleSchema: EnrichedModuleSchema
	inverseRelationSchema: EnrichedRelationSchema | null
	moduleSchema: EnrichedModuleSchema
}>()
</script>

<style scoped lang="scss">
.relation__diagram-container {
	padding: 40px;
	display: flex;
	width: 100%;
	align-items: center;
	gap: 10px;
	background: var(--p-surface-0);

	@media (prefers-color-scheme: dark) {
		background: var(--p-surface-950);
	}

	.relation__model-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 130px;
		height: 130px;
		justify-content: center;
		gap: 8px;
		border-radius: 20px;
		border: 1px solid var(--p-surface-100);

		@media (prefers-color-scheme: dark) {
			border: 2px solid var(--p-surface-800);
		}

		i {
			font-size: 50px;
			color: var(--p-primary-500);
		}

		.relation__model-name {
			font-size: 16px;
		}
	}

	.relation__arrows-container {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;

		.relation__relation-name-container {
			display: flex;
			gap: 5px;
			margin-bottom: -4px;

			&--inverse {
				margin-top: -7px;
			}

			.relation__relation-name,
			.relation__relation-type {
				font-size: 18px;
			}

			.relation__relation-name {
				color: var(--p-primary-500);
				font-weight: 600;
			}

			.relation__relation-type {
				color: var(--p-blue-500);

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.relation__relation-arrow {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			margin-bottom: -2px;
			margin-top: -2px;

			.relation__relation-arrow-line {
				background: var(--p-surface-300);
				height: 2px;
				width: calc(100% - 30px);

				@media (prefers-color-scheme: dark) {
					background: var(--p-surface-700);
				}
			}

			.relation__relation-arrow-tip {
				font-size: 30px;
				margin-left: -1px;
				color: var(--p-surface-300);

				@media (prefers-color-scheme: dark) {
					color: var(--p-surface-700);
				}

				&--inverse {
					transform: rotate(180deg);
					margin-left: 0;
					margin-right: -1px;
				}
			}
		}
	}
}
</style>
