import type { IApi } from '@/helpers/api/Api'
import ListState from '@/helpers/states/ListState'
import type { LaravelPaginationResponse } from '@/interfaces/Laravel'
import type { Title } from '@/interfaces/Model'

export class Model<
	A extends IApi<T, TList, TStorePayload, TUpdatePayload>,
	L extends ListState<A, T, TList>,
	T extends { id: number | string },
	TList extends LaravelPaginationResponse<T>,
	TStorePayload,
	TUpdatePayload,
> {
	api!: A
	useListState!: () => L
	title!: Title
}
