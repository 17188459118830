import type {
	CodeDelivery,
	CodeDeliveryList,
	CodeDeliveryStorePayload,
	CodeDeliveryUpdatePayload,
} from '@/interfaces/CodeDelivery'
import Api from '@/helpers/api/Api'

export default class CodeDeliverysApi extends Api<
	CodeDelivery,
	CodeDeliveryList,
	CodeDeliveryStorePayload,
	CodeDeliveryUpdatePayload
> {
	route = 'code_deliveries'
}
