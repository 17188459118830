import type { LaravelPaginationResponse } from './Laravel'
import type { Title } from './Model'

export interface CodeDeliveryStorePayload {
	status: string
	failure_reason?: string
	build_id?: number
	repository_config_id?: number
}

export interface CodeDeliveryUpdatePayload {
	status: string
	failure_reason?: string
	build_id?: number
	repository_config_id?: number
}

export interface CodeDelivery {
	id: string | number
	status: string
	failure_reason?: string
	build_id?: number
	repository_config_id?: number
	created_at: string
	updated_at: string
}

export interface CodeDeliveryList extends LaravelPaginationResponse<CodeDelivery> {}

export const title: Title = 'status'
