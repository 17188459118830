import {
	title,
	type RepositoryConfig as RepositoryConfigType,
	type RepositoryConfigList,
	type RepositoryConfigStorePayload,
	type RepositoryConfigUpdatePayload,
} from '@/interfaces/RepositoryConfig'
import RepositoryConfigsApi from '../api/RepositoryConfigsApi'
import RepositoryConfigListState from '../states/RepositoryConfigListState'
import { Model } from './Model'

export class RepositoryConfigModel extends Model<
	RepositoryConfigsApi,
	RepositoryConfigListState,
	RepositoryConfigType,
	RepositoryConfigList,
	RepositoryConfigStorePayload,
	RepositoryConfigUpdatePayload
> {
	api = new RepositoryConfigsApi()
	useListState = () => new RepositoryConfigListState()
	title = title
}

const RepositoryConfig = new RepositoryConfigModel()
export default RepositoryConfig
