<template>
	<Card
		v-show="deployment && listState.isLoaded"
		class="deployment-status"
		:color="activeColor"
		border="0">
		<template #content>
			<Avatar
				class="deployment-status__avatar"
				:class="{
					'deployment-status__avatar--success': deployment?.status === 'success',
					'deployment-status__avatar--failure': deployment?.status === 'failure',
				}">
				<i
					v-if="deployment?.status === 'success'"
					class="fa-light fa-check"></i>
				<i
					v-else-if="deployment?.status === 'failure'"
					class="fa-solid fa-exclamation"></i>
				<ProgressSpinner
					v-else
					class="deployment-status__avatar-loader"
					indeterminate />
			</Avatar>
			<div class="deployment-status__status-container">
				<div class="deployment-status__label">Deployment status</div>
				<div
					v-if="deployment?.status === 'building'"
					class="deployment-status__status">
					Building
				</div>
				<div
					v-else-if="deployment?.status === 'pending'"
					class="deployment-status__status">
					Deploying application
				</div>
				<div
					v-else-if="deployment?.status === 'deployed'"
					class="deployment-status__status">
					Starting
				</div>
				<div
					v-else-if="deployment?.status === 'success'"
					class="deployment-status__status">
					Deployed
				</div>
				<div
					v-else-if="deployment?.status === 'failure'"
					class="deployment-status__status">
					Unexpected error
				</div>
				<div
					v-else
					class="deployment-status__status">
					Loading
				</div>
			</div>
		</template>
	</Card>
</template>

<script setup lang="ts">
import type { Build } from '@/interfaces/Build'
import { computed, defineProps, onBeforeMount, onUnmounted, ref, watch } from 'vue'
import DeploymentModel from '@/helpers/models/Deployment'
import Avatar from 'primevue/avatar'
import Card from 'primevue/card'
import ProgressSpinner from 'primevue/progressspinner'

const emit = defineEmits(['start-loading', 'stop-loading', 'deployed'])

const props = defineProps<{
	buildId: Build['id'] | undefined
}>()

let statusInterval: any = null
const listLoading = ref(false)
const listLoaded = ref(false)
const listState = DeploymentModel.useListState()

listState.defaultParams = {
	per_page: 1,
	fromRelation: {
		model: 'App\\Models\\Build',
		id: props.buildId!,
		relation: 'deployments',
	},
	with: ['server', 'creator'],
	orderBy: 'created_at',
	order: 'desc',
}

const deployment = computed(() => {
	return listState?.list.value[0]
})

const activeColor = computed(() => {
	if (deployment.value?.status === 'success') return 'success'
	if (deployment.value?.status === 'failure') return 'error'
	return 'primary'
})

watch(
	() => props.buildId,
	() => {
		if (!props.buildId) return
		listState.defaultParams.fromRelation!.id = props.buildId
		refresh()
	},
)

onBeforeMount(() => {
	if (!props.buildId) return
	refresh()
	setStatusInterval()
})

onUnmounted(() => {
	clearInterval(statusInterval)
	listLoaded.value = false
})

function setStatusInterval() {
	statusInterval = setInterval(() => {
		if (
			deployment.value?.status === 'success' ||
			deployment.value?.status === 'failure' ||
			!props.buildId
		) {
			emit('deployed')
			refresh()
			clearInterval(statusInterval)
			return
		}
		refresh()
	}, 5000)
}

async function refresh() {
	if (!listState.defaultParams.fromRelation?.id) return
	if (!listLoaded.value) {
		listLoading.value = true
		emit('start-loading')
	}
	await listState.getList()
	if (!listLoaded.value) {
		listLoading.value = false
		emit('stop-loading')
	}
	listLoaded.value = true
}

defineExpose({
	setStatusInterval,
})
</script>

<style scoped lang="scss">
.deployment-status {
	background: var(--p-surface-0);

	@media (prefers-color-scheme: dark) {
		background: var(--p-surface-900);
	}

	&:deep(.p-card-content) {
		display: flex;
		align-items: center;
		gap: 16px;

		.deployment-status__avatar {
			color: white;
			height: 32px;
			width: 32px;
			background: transparent;

			&--success {
				background: var(--p-emerald-500);
			}

			&--failure {
				background: var(--p-red-500);
			}
		}

		.deployment-status__label {
			font-size: 12px;
			color: var(--p-text-secondary);
		}

		.deployment-status__status {
			font-size: 14px;
		}
	}
}
</style>
