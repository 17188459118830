<template>
	<svg
		width="90"
		height="28"
		viewBox="0 0 90 28"
		fill="none"
		version="1.1">
		<circle
			cx="14"
			cy="14"
			stroke="#000000"
			stroke-width="5"
			r="11.5" />
		<circle
			cx="76"
			cy="14"
			stroke="#000000"
			stroke-width="5"
			r="11.5" />
		<rect
			x="23"
			y="11"
			width="44"
			height="6"
			fill="#000000" />
	</svg>
</template>
