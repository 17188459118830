import type { EnrichedColumnSchema } from './enrichedModuleSchema'

export type ParameterType =
	| typeof Number
	| typeof String
	| typeof Boolean
	| [typeof String]
	| null
	| 'any'
	| [typeof String, 'any']
	| [[typeof String]]
	| ['any', 'any']
	| [typeof Number, typeof Number]
	| [typeof Number]
	| [[typeof Number, typeof Number]]
	| [typeof String, typeof String]
	| ['any']
	| [typeof String]
	| [[typeof String]]

export type ColumnParameter = {
	type: ParameterType
	columnParameter: string | number
	columnParameterLabel: string
	required: boolean
	allowed: boolean
}

export type ColumnModifier = {
	type: ParameterType
	columnModifier: string
	required: boolean
	allowed: boolean
	global: boolean
}

export type ColumnValidator = {
	type: ParameterType
	optionalType?: ParameterType
	columnValidator: string
	allowed: boolean
	global: boolean
}

export type TypeConstraint = {
	columnParameters: ColumnParameter[]
	columnModifiers: ColumnModifier[]
	columnValidators: ColumnValidator[]
}

export const typeConstraints: Record<string, TypeConstraint> = {
	length: {
		columnParameters: [
			{
				type: Number,
				columnParameterLabel: 'length',
				columnParameter: 'length',
				required: false,
				allowed: true,
			},
		],
		columnModifiers: [],
		columnValidators: [],
	},
	fixed: {
		columnParameters: [
			{
				type: Boolean,
				columnParameterLabel: 'fixed',
				columnParameter: 'fixed',
				required: false,
				allowed: true,
			},
		],
		columnModifiers: [],
		columnValidators: [],
	},
	precision: {
		columnParameters: [
			{
				type: Number,
				columnParameterLabel: 'precision',
				columnParameter: 'precision',
				required: false,
				allowed: true,
			},
		],
		columnModifiers: [],
		columnValidators: [],
	},
	total: {
		columnParameters: [
			{
				type: Number,
				columnParameterLabel: 'total',
				columnParameter: 'total',
				required: false,
				allowed: true,
			},
		],
		columnModifiers: [],
		columnValidators: [],
	},
	places: {
		columnParameters: [
			{
				type: Number,
				columnParameterLabel: 'places',
				columnParameter: 'places',
				required: false,
				allowed: true,
			},
		],
		columnModifiers: [],
		columnValidators: [],
	},
	enumValues: {
		columnParameters: [
			{
				type: [String],
				columnParameterLabel: 'enumValues',
				columnParameter: 1,
				required: true,
				allowed: true,
			},
		],
		columnModifiers: [],
		columnValidators: [],
	},
	model: {
		columnParameters: [
			{
				type: String,
				columnParameterLabel: 'model',
				columnParameter: 0,
				required: true,
				allowed: true,
			},
		],
		columnModifiers: [],
		columnValidators: [],
	},
	_charset: {
		columnModifiers: [
			{
				type: String,
				columnModifier: 'charset',
				global: false,
				required: false,
				allowed: false,
			},
		],
		columnParameters: [],
		columnValidators: [],
	},
	_after: {
		columnModifiers: [
			{
				type: String,
				columnModifier: 'after',
				global: true,
				required: false,
				allowed: false,
			},
		],
		columnParameters: [],
		columnValidators: [],
	},
	autoIncrement: {
		columnModifiers: [
			{
				type: null,
				columnModifier: 'autoIncrement',
				global: false,
				required: false,
				allowed: true,
			},
		],
		columnParameters: [],
		columnValidators: [],
	},
	collation: {
		columnModifiers: [
			{
				type: String,
				columnModifier: 'collation',
				global: false,
				required: false,
				allowed: true,
			},
		],
		columnParameters: [],
		columnValidators: [],
	},
	_comment: {
		columnModifiers: [
			{
				type: String,
				columnModifier: 'comment',
				global: true,
				required: false,
				allowed: false,
			},
		],
		columnParameters: [],
		columnValidators: [],
	},
	default: {
		columnModifiers: [
			{
				type: 'any',
				columnModifier: 'default',
				global: true,
				required: false,
				allowed: true,
			},
		],
		columnParameters: [],
		columnValidators: [],
	},
	_first: {
		columnModifiers: [
			{
				type: null,
				columnModifier: 'first',
				global: true,
				required: false,
				allowed: false,
			},
		],
		columnParameters: [],
		columnValidators: [],
	},
	_from: {
		columnModifiers: [
			{
				type: Number,
				columnModifier: 'from',
				global: false,
				required: false,
				allowed: false,
			},
		],
		columnParameters: [],
		columnValidators: [],
	},
	_invisible: {
		columnModifiers: [
			{
				type: null,
				columnModifier: 'invisible',
				global: true,
				required: false,
				allowed: false,
			},
		],
		columnParameters: [],
		columnValidators: [],
	},
	nullable: {
		columnModifiers: [
			{
				type: Boolean,
				columnModifier: 'nullable',
				global: true,
				required: false,
				allowed: true,
			},
		],
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'nullable',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
	},
	_storedAs: {
		columnModifiers: [
			{
				type: String,
				columnModifier: 'storedAs',
				global: true,
				required: false,
				allowed: false,
			},
		],
		columnParameters: [],
		columnValidators: [],
	},
	_unsigned: {
		columnModifiers: [
			{
				type: null,
				columnModifier: 'unsigned',
				global: false,
				required: false,
				allowed: false,
			},
		],
		columnParameters: [],
		columnValidators: [],
	},
	useCurrent: {
		columnModifiers: [
			{
				type: null,
				columnModifier: 'useCurrent',
				global: false,
				required: false,
				allowed: true,
			},
		],
		columnParameters: [],
		columnValidators: [],
	},
	_useCurrentOnUpdate: {
		columnModifiers: [
			{
				type: null,
				columnModifier: 'useCurrentOnUpdate',
				global: false,
				required: false,
				allowed: false,
			},
		],
		columnParameters: [],
		columnValidators: [],
	},
	_virtualAs: {
		columnModifiers: [
			{
				type: String,
				columnModifier: 'virtualAs',
				global: true,
				required: false,
				allowed: false,
			},
		],
		columnParameters: [],
		columnValidators: [],
	},
	_generatedAs: {
		columnModifiers: [
			{
				type: String,
				columnModifier: 'generatedAs',
				global: true,
				required: false,
				allowed: false,
			},
		],
		columnParameters: [],
		columnValidators: [],
	},
	_always: {
		columnModifiers: [
			{
				type: null,
				columnModifier: 'always',
				global: true,
				required: false,
				allowed: false,
			},
		],
		columnParameters: [],
		columnValidators: [],
	},
	accepted: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'accepted',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	accepted_if: {
		columnValidators: [
			{
				type: [String, 'any'],
				columnValidator: 'accepted_if',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	active_url: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'active_url',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	after: {
		columnValidators: [
			{
				type: [String],
				columnValidator: 'after',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	after_or_equal: {
		columnValidators: [
			{
				type: [String],
				columnValidator: 'after_or_equal',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	alpha: {
		columnValidators: [
			{
				type: Boolean,
				optionalType: [String],
				columnValidator: 'alpha',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	alpha_dash: {
		columnValidators: [
			{
				type: Boolean,
				optionalType: [String],
				columnValidator: 'alpha_dash',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	alpha_num: {
		columnValidators: [
			{
				type: Boolean,
				optionalType: [String],
				columnValidator: 'alpha_num',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	array: {
		columnValidators: [
			{
				type: [[String]],
				columnValidator: 'array',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	ascii: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'ascii',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	bail: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'bail',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	before: {
		columnValidators: [
			{
				type: [String],
				columnValidator: 'before',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	before_or_equal: {
		columnValidators: [
			{
				type: [String],
				columnValidator: 'before_or_equal',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	between: {
		columnValidators: [
			{
				type: ['any', 'any'],
				columnValidator: 'between',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	boolean: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'boolean',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	confirmed: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'confirmed',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	contains: {
		columnValidators: [
			{
				type: [[String]],
				columnValidator: 'contains',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	current_password: {
		columnValidators: [
			{
				type: Boolean,
				optionalType: [String],
				columnValidator: 'current_password',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	date: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'date',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	date_equals: {
		columnValidators: [
			{
				type: [String],
				columnValidator: 'date_equals',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	date_format: {
		columnValidators: [
			{
				type: [[String]],
				columnValidator: 'date_format',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	decimal: {
		columnValidators: [
			{
				type: [Number, Number],
				columnValidator: 'decimal',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	declined: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'declined',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	declined_if: {
		columnValidators: [
			{
				type: [String, 'any'],
				columnValidator: 'declined_if',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	different: {
		columnValidators: [
			{
				type: [String],
				columnValidator: 'different',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	digits: {
		columnValidators: [
			{
				type: [Number],
				columnValidator: 'digits',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	digits_between: {
		columnValidators: [
			{
				type: [Number, Number],
				columnValidator: 'digits_between',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	dimensions: {
		columnValidators: [
			{
				type: [[Number, Number]],
				columnValidator: 'dimensions',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	distinct: {
		columnValidators: [
			{
				type: Boolean,
				optionalType: [String],
				columnValidator: 'distinct',
				allowed: false,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	doesnt_start_with: {
		columnValidators: [
			{
				type: [String],
				columnValidator: 'doesnt_start_with',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	doesnt_end_with: {
		columnValidators: [
			{
				type: [String],
				columnValidator: 'doesnt_end_with',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	email: {
		columnValidators: [
			{
				type: Boolean,
				optionalType: [[String]],
				columnValidator: 'email',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	ends_with: {
		columnValidators: [
			{
				type: [String],
				columnValidator: 'ends_with',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	enum: {
		columnValidators: [
			{
				type: [[String]],
				columnValidator: 'enum',
				allowed: false,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	exclude: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'exclude',
				allowed: false,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	exclude_if: {
		columnValidators: [
			{
				type: [String, 'any'],
				columnValidator: 'exclude_if',
				allowed: false,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	exclude_unless: {
		columnValidators: [
			{
				type: [String, 'any'],
				columnValidator: 'exclude_unless',
				allowed: false,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	exclude_with: {
		columnValidators: [
			{
				type: [String],
				columnValidator: 'exclude_with',
				allowed: false,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	exclude_without: {
		columnValidators: [
			{
				type: [String],
				columnValidator: 'exclude_without',
				allowed: false,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	exists: {
		columnValidators: [
			{
				type: [String, String],
				columnValidator: 'exists',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	extensions: {
		columnValidators: [
			{
				type: [[String]],
				columnValidator: 'extensions',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	file: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'file',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	filled: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'filled',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	gt: {
		columnValidators: [
			{
				type: ['any'],
				columnValidator: 'gt',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	gte: {
		columnValidators: [
			{
				type: ['any'],
				columnValidator: 'gte',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	hex_color: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'hex_color',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	image: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'image',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	in: {
		columnValidators: [
			{
				type: [[String]],
				columnValidator: 'in',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	in_array: {
		columnValidators: [
			{
				type: [String],
				columnValidator: 'in_array',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	integer: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'integer',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	ip: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'ip',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	ip4: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'ip4',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	ip6: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'ip6',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	json: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'json',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	lt: {
		columnValidators: [
			{
				type: [String],
				columnValidator: 'lt',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	lte: {
		columnValidators: [
			{
				type: [String],
				columnValidator: 'lte',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	lowercase: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'lowercase',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	list: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'list',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	mac_address: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'mac_address',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	max: {
		columnValidators: [
			{
				type: ['any'],
				columnValidator: 'max',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	max_digits: {
		columnValidators: [
			{
				type: [Number],
				columnValidator: 'max_digits',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	mimetypes: {
		columnValidators: [
			{
				type: [[String]],
				columnValidator: 'mime_types',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	mimes: {
		columnValidators: [
			{
				type: [[String]],
				columnValidator: 'mimes',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	min: {
		columnValidators: [
			{
				type: ['any'],
				columnValidator: 'min',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	min_digits: {
		columnValidators: [
			{
				type: [Number],
				columnValidator: 'min_digits',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	multiple_of: {
		columnValidators: [
			{
				type: ['any'],
				columnValidator: 'multiple_of',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	missing: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'missing',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	missing_if: {
		columnValidators: [
			{
				type: [String, 'any'],
				columnValidator: 'missing_if',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	missing_unless: {
		columnValidators: [
			{
				type: [String, 'any'],
				columnValidator: 'missing_unless',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	missing_with: {
		columnValidators: [
			{
				type: [[String]],
				columnValidator: 'missing_with',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	missing_with_all: {
		columnValidators: [
			{
				type: [[String]],
				columnValidator: 'missing_without',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	not_in: {
		columnValidators: [
			{
				type: [[String]],
				columnValidator: 'not_in',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	not_regex: {
		columnValidators: [
			{
				type: [String],
				columnValidator: 'not_regex',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	numeric: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'numeric',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	present: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'present',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	present_if: {
		columnValidators: [
			{
				type: [String, 'any'],
				columnValidator: 'present_if',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	present_unless: {
		columnValidators: [
			{
				type: [String, 'any'],
				columnValidator: 'present_unless',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	present_with: {
		columnValidators: [
			{
				type: [[String]],
				columnValidator: 'present_with',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	present_with_all: {
		columnValidators: [
			{
				type: [[String]],
				columnValidator: 'present_without',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	prohibited: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'prohibited',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	prohibited_if: {
		columnValidators: [
			{
				type: [String, 'any'],
				columnValidator: 'prohibited_if',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	prohibited_unless: {
		columnValidators: [
			{
				type: [String, 'any'],
				columnValidator: 'prohibited_unless',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	prohibits: {
		columnValidators: [
			{
				type: [[String]],
				columnValidator: 'prohibits',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	regex: {
		columnValidators: [
			{
				type: [String],
				columnValidator: 'regex',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	required: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'required',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	required_if: {
		columnValidators: [
			{
				type: [String, 'any'],
				columnValidator: 'required_if',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	required_if_accepted: {
		columnValidators: [
			{
				type: [String],
				columnValidator: 'required_if_accepted',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	required_if_declined: {
		columnValidators: [
			{
				type: [String, 'any'],
				columnValidator: 'required_if_not',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	required_unless: {
		columnValidators: [
			{
				type: [String, 'any'],
				columnValidator: 'required_unless',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	required_with: {
		columnValidators: [
			{
				type: [[String]],
				columnValidator: 'required_with',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	required_with_all: {
		columnValidators: [
			{
				type: [[String]],
				columnValidator: 'required_with_all',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	required_without: {
		columnValidators: [
			{
				type: [[String]],
				columnValidator: 'required_without',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	required_without_all: {
		columnValidators: [
			{
				type: [[String]],
				columnValidator: 'required_without_all',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	same: {
		columnValidators: [
			{
				type: [String],
				columnValidator: 'same',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	size: {
		columnValidators: [
			{
				type: [Number],
				columnValidator: 'size',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	starts_with: {
		columnValidators: [
			{
				type: [[String]],
				columnValidator: 'starts_with',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	string: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'string',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	timezone: {
		columnValidators: [
			{
				type: Boolean,
				optionalType: [[String]],
				columnValidator: 'timezone',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	unique: {
		columnValidators: [
			{
				type: [String, String],
				columnValidator: 'unique',
				allowed: true,
				global: true,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	uppercase: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'uppercase',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	url: {
		columnValidators: [
			{
				type: Boolean,
				optionalType: [[String]],
				columnValidator: 'url',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	ulid: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'ulid',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
	uuid: {
		columnValidators: [
			{
				type: Boolean,
				columnValidator: 'uuid',
				allowed: true,
				global: false,
			},
		],
		columnParameters: [],
		columnModifiers: [],
	},
} as const

export const globalConstraints = Object.entries(typeConstraints).reduce(
	(acc, [key, value]) => {
		if (
			value.columnModifiers.some((m) => m.global) ||
			value.columnValidators.some((v) => v.global)
		) {
			acc[key] = {
				columnModifiers: value.columnModifiers.filter((m) => m.global),
				columnValidators: value.columnValidators.filter((v) => v.global),
			}
		}
		return acc
	},
	{} as Record<string, { columnValidators: ColumnValidator[]; columnModifiers: ColumnModifier[] }>,
)

type TypeConstraints = Record<
	string,
	{
		columnParameters?: ColumnParameter[]
		columnModifiers?: ColumnModifier[]
		allowedValidators?: ColumnValidator[]
		enforcedValidators?: ColumnValidator[]
	}
>

export const supportedConstraints: TypeConstraints = {
	bigInteger: {
		columnModifiers: [
			...typeConstraints.autoIncrement.columnModifiers,
			...typeConstraints._from.columnModifiers,
			...typeConstraints._unsigned.columnModifiers,
		],
		allowedValidators: [
			...typeConstraints.between.columnValidators,
			...typeConstraints.current_password.columnValidators,
			...typeConstraints.digits.columnValidators,
			...typeConstraints.digits_between.columnValidators,
			...typeConstraints.integer.columnValidators,
			...typeConstraints.max_digits.columnValidators,
			...typeConstraints.min_digits.columnValidators,
			...typeConstraints.multiple_of.columnValidators,
			...typeConstraints.numeric.columnValidators,
			...typeConstraints.size.columnValidators,
		],
	},
	binary: {
		columnParameters: [
			...typeConstraints.length.columnParameters,
			...typeConstraints.fixed.columnParameters,
		],
		columnModifiers: [...typeConstraints.collation.columnModifiers],
		allowedValidators: [
			...typeConstraints.between.columnValidators,
			...typeConstraints.dimensions.columnValidators,
			...typeConstraints.extensions.columnValidators,
			...typeConstraints.file.columnValidators,
			...typeConstraints.image.columnValidators,
			...typeConstraints.mimetypes.columnValidators,
			...typeConstraints.mimes.columnValidators,
			...typeConstraints.size.columnValidators,
		],
	},
	boolean: {
		allowedValidators: [
			...typeConstraints.accepted.columnValidators,
			...typeConstraints.accepted_if.columnValidators,
			...typeConstraints.declined.columnValidators,
			...typeConstraints.declined_if.columnValidators,
		],
		enforcedValidators: [...typeConstraints.boolean.columnValidators],
	},
	char: {
		columnParameters: [...typeConstraints.length.columnParameters],
		columnModifiers: [...typeConstraints.collation.columnModifiers],
		allowedValidators: [
			...typeConstraints.alpha.columnValidators,
			...typeConstraints.alpha_dash.columnValidators,
			...typeConstraints.alpha_num.columnValidators,
			...typeConstraints.ascii.columnValidators,
			...typeConstraints.between.columnValidators,
			...typeConstraints.integer.columnValidators,
		],
	},
	dateTimeTz: {
		columnParameters: [...typeConstraints.precision.columnParameters],
		columnModifiers: [
			...typeConstraints.useCurrent.columnModifiers,
			...typeConstraints._useCurrentOnUpdate.columnModifiers,
		],
		allowedValidators: [
			...typeConstraints.after.columnValidators,
			...typeConstraints.after_or_equal.columnValidators,
			...typeConstraints.before.columnValidators,
			...typeConstraints.before_or_equal.columnValidators,
			...typeConstraints.between.columnValidators,
			...typeConstraints.date_equals.columnValidators,
			...typeConstraints.date_format.columnValidators,
			...typeConstraints.timezone.columnValidators,
		],
		enforcedValidators: [...typeConstraints.date.columnValidators],
	},
	dateTime: {
		columnParameters: [...typeConstraints.precision.columnParameters],
		columnModifiers: [
			...typeConstraints.useCurrent.columnModifiers,
			...typeConstraints._useCurrentOnUpdate.columnModifiers,
		],
		allowedValidators: [
			...typeConstraints.after.columnValidators,
			...typeConstraints.after_or_equal.columnValidators,
			...typeConstraints.before.columnValidators,
			...typeConstraints.before_or_equal.columnValidators,
			...typeConstraints.between.columnValidators,
			...typeConstraints.timezone.columnValidators,
		],
		enforcedValidators: [...typeConstraints.date.columnValidators],
	},
	date: {
		columnModifiers: [
			...typeConstraints.useCurrent.columnModifiers,
			...typeConstraints._useCurrentOnUpdate.columnModifiers,
		],
		allowedValidators: [
			...typeConstraints.after.columnValidators,
			...typeConstraints.after_or_equal.columnValidators,
			...typeConstraints.before.columnValidators,
			...typeConstraints.before_or_equal.columnValidators,
			...typeConstraints.between.columnValidators,
			...typeConstraints.date_equals.columnValidators,
			...typeConstraints.date_format.columnValidators,
			...typeConstraints.timezone.columnValidators,
		],
		enforcedValidators: [...typeConstraints.date.columnValidators],
	},
	decimal: {
		columnParameters: [
			...typeConstraints.total.columnParameters,
			...typeConstraints.places.columnParameters,
		],
		allowedValidators: [
			...typeConstraints.between.columnValidators,
			...typeConstraints.current_password.columnValidators,
			...typeConstraints.decimal.columnValidators,
			...typeConstraints.digits.columnValidators,
			...typeConstraints.digits_between.columnValidators,
			...typeConstraints.integer.columnValidators,
			...typeConstraints.max_digits.columnValidators,
			...typeConstraints.min_digits.columnValidators,
			...typeConstraints.multiple_of.columnValidators,
			...typeConstraints.numeric.columnValidators,
			...typeConstraints.size.columnValidators,
		],
	},
	double: {
		allowedValidators: [
			...typeConstraints.between.columnValidators,
			...typeConstraints.current_password.columnValidators,
			...typeConstraints.digits.columnValidators,
			...typeConstraints.digits_between.columnValidators,
			...typeConstraints.integer.columnValidators,
			...typeConstraints.max_digits.columnValidators,
			...typeConstraints.min_digits.columnValidators,
			...typeConstraints.multiple_of.columnValidators,
			...typeConstraints.numeric.columnValidators,
			...typeConstraints.size.columnValidators,
		],
	},
	enum: {
		columnParameters: [...typeConstraints.enumValues.columnParameters],
		columnModifiers: [...typeConstraints.collation.columnModifiers],
		allowedValidators: [...typeConstraints.enum.columnValidators],
	},
	float: {
		columnParameters: [...typeConstraints.precision.columnParameters],
		allowedValidators: [
			...typeConstraints.between.columnValidators,
			...typeConstraints.current_password.columnValidators,
			...typeConstraints.decimal.columnValidators,
			...typeConstraints.digits.columnValidators,
			...typeConstraints.digits_between.columnValidators,
			...typeConstraints.integer.columnValidators,
			...typeConstraints.max_digits.columnValidators,
			...typeConstraints.min_digits.columnValidators,
			...typeConstraints.multiple_of.columnValidators,
			...typeConstraints.numeric.columnValidators,
			...typeConstraints.size.columnValidators,
		],
	},
	foreignId: {},
	// WARN: Not supported due to special parameter case
	// foreignIdFor: {
	// 	columnParameters: [...typeConstraints.model.columnParameters],
	// },
	foreignUlid: {},
	foreignUuid: {},
	id: {},
	integer: {
		columnModifiers: [
			...typeConstraints.autoIncrement.columnModifiers,
			...typeConstraints._from.columnModifiers,
			...typeConstraints._unsigned.columnModifiers,
		],
		allowedValidators: [
			...typeConstraints.between.columnValidators,
			...typeConstraints.current_password.columnValidators,
			...typeConstraints.digits.columnValidators,
			...typeConstraints.digits_between.columnValidators,
			...typeConstraints.max_digits.columnValidators,
			...typeConstraints.min_digits.columnValidators,
			...typeConstraints.multiple_of.columnValidators,
			...typeConstraints.numeric.columnValidators,
			...typeConstraints.size.columnValidators,
		],
		enforcedValidators: [...typeConstraints.integer.columnValidators],
	},
	ipAddress: {
		allowedValidators: [
			...typeConstraints.ip.columnValidators,
			...typeConstraints.ip4.columnValidators,
			...typeConstraints.ip6.columnValidators,
		],
	},
	json: {
		allowedValidators: [
			...typeConstraints.array.columnValidators,
			...typeConstraints.between.columnValidators,
			...typeConstraints.contains.columnValidators,
			...typeConstraints.list.columnValidators,
			...typeConstraints.size.columnValidators,
		],
		enforcedValidators: [...typeConstraints.json.columnValidators],
	},
	jsonb: {
		allowedValidators: [
			...typeConstraints.array.columnValidators,
			...typeConstraints.between.columnValidators,
			...typeConstraints.contains.columnValidators,
			...typeConstraints.list.columnValidators,
			...typeConstraints.size.columnValidators,
		],
		enforcedValidators: [...typeConstraints.json.columnValidators],
	},
	longText: {
		columnModifiers: [
			...typeConstraints._charset.columnModifiers,
			...typeConstraints.collation.columnModifiers,
		],
		allowedValidators: [
			...typeConstraints.active_url.columnValidators,
			...typeConstraints.alpha.columnValidators,
			...typeConstraints.alpha_dash.columnValidators,
			...typeConstraints.alpha_num.columnValidators,
			...typeConstraints.array.columnValidators,
			...typeConstraints.ascii.columnValidators,
			...typeConstraints.between.columnValidators,
			...typeConstraints.contains.columnValidators,
			...typeConstraints.current_password.columnValidators,
			...typeConstraints.date.columnValidators,
			...typeConstraints.date_equals.columnValidators,
			...typeConstraints.date_format.columnValidators,
			...typeConstraints.decimal.columnValidators,
			...typeConstraints.digits.columnValidators,
			...typeConstraints.digits_between.columnValidators,
			...typeConstraints.doesnt_start_with.columnValidators,
			...typeConstraints.doesnt_end_with.columnValidators,
			...typeConstraints.email.columnValidators,
			...typeConstraints.ends_with.columnValidators,
			...typeConstraints.hex_color.columnValidators,
			...typeConstraints.integer.columnValidators,
			...typeConstraints.ip.columnValidators,
			...typeConstraints.ip4.columnValidators,
			...typeConstraints.ip6.columnValidators,
			...typeConstraints.lowercase.columnValidators,
			...typeConstraints.list.columnValidators,
			...typeConstraints.mac_address.columnValidators,
			...typeConstraints.max_digits.columnValidators,
			...typeConstraints.min_digits.columnValidators,
			...typeConstraints.multiple_of.columnValidators,
			...typeConstraints.numeric.columnValidators,
			...typeConstraints.size.columnValidators,
			...typeConstraints.starts_with.columnValidators,
			...typeConstraints.string.columnValidators,
			...typeConstraints.timezone.columnValidators,
			...typeConstraints.uppercase.columnValidators,
			...typeConstraints.url.columnValidators,
			...typeConstraints.ulid.columnValidators,
			...typeConstraints.uuid.columnValidators,
		],
	},
	macAddress: {
		enforcedValidators: [...typeConstraints.mac_address.columnValidators],
	},
	mediumInteger: {
		columnModifiers: [
			...typeConstraints.autoIncrement.columnModifiers,
			...typeConstraints._from.columnModifiers,
			...typeConstraints._unsigned.columnModifiers,
			...typeConstraints.size.columnModifiers,
		],
		allowedValidators: [
			...typeConstraints.between.columnValidators,
			...typeConstraints.current_password.columnValidators,
		],
		enforcedValidators: [...typeConstraints.integer.columnValidators],
	},
	mediumText: {
		columnModifiers: [
			...typeConstraints._charset.columnModifiers,
			...typeConstraints.collation.columnModifiers,
		],
		allowedValidators: [
			...typeConstraints.active_url.columnValidators,
			...typeConstraints.alpha.columnValidators,
			...typeConstraints.alpha_dash.columnValidators,
			...typeConstraints.alpha_num.columnValidators,
			...typeConstraints.array.columnValidators,
			...typeConstraints.ascii.columnValidators,
			...typeConstraints.between.columnValidators,
			...typeConstraints.contains.columnValidators,
			...typeConstraints.current_password.columnValidators,
			...typeConstraints.date.columnValidators,
			...typeConstraints.date_equals.columnValidators,
			...typeConstraints.date_format.columnValidators,
			...typeConstraints.decimal.columnValidators,
			...typeConstraints.digits.columnValidators,
			...typeConstraints.digits_between.columnValidators,
			...typeConstraints.doesnt_start_with.columnValidators,
			...typeConstraints.doesnt_end_with.columnValidators,
			...typeConstraints.email.columnValidators,
			...typeConstraints.ends_with.columnValidators,
			...typeConstraints.hex_color.columnValidators,
			...typeConstraints.integer.columnValidators,
			...typeConstraints.ip.columnValidators,
			...typeConstraints.ip4.columnValidators,
			...typeConstraints.ip6.columnValidators,
			...typeConstraints.lowercase.columnValidators,
			...typeConstraints.list.columnValidators,
			...typeConstraints.mac_address.columnValidators,
			...typeConstraints.max_digits.columnValidators,
			...typeConstraints.min_digits.columnValidators,
			...typeConstraints.multiple_of.columnValidators,
			...typeConstraints.numeric.columnValidators,
			...typeConstraints.size.columnValidators,
			...typeConstraints.starts_with.columnValidators,
			...typeConstraints.string.columnValidators,
			...typeConstraints.timezone.columnValidators,
			...typeConstraints.uppercase.columnValidators,
			...typeConstraints.url.columnValidators,
			...typeConstraints.ulid.columnValidators,
			...typeConstraints.uuid.columnValidators,
		],
	},
	morphs: {},
	rememberToken: {},
	set: {
		columnParameters: [...typeConstraints.enumValues.columnParameters],
		columnModifiers: [...typeConstraints.collation.columnModifiers],
		allowedValidators: [
			...typeConstraints.array.columnValidators,
			...typeConstraints.contains.columnValidators,
			...typeConstraints.size.columnValidators,
		],
	},
	smallInteger: {
		columnModifiers: [
			...typeConstraints.autoIncrement.columnModifiers,
			...typeConstraints._from.columnModifiers,
			...typeConstraints._unsigned.columnModifiers,
		],
		allowedValidators: [
			...typeConstraints.between.columnValidators,
			...typeConstraints.current_password.columnValidators,
			...typeConstraints.digits.columnValidators,
			...typeConstraints.digits_between.columnValidators,
			...typeConstraints.max_digits.columnValidators,
			...typeConstraints.min_digits.columnValidators,
			...typeConstraints.multiple_of.columnValidators,
			...typeConstraints.numeric.columnValidators,
			...typeConstraints.size.columnValidators,
		],
		enforcedValidators: [...typeConstraints.integer.columnValidators],
	},
	softDeletesTz: {
		columnParameters: [...typeConstraints.precision.columnParameters],
		columnModifiers: [
			...typeConstraints.useCurrent.columnModifiers,
			...typeConstraints._useCurrentOnUpdate.columnModifiers,
		],
		allowedValidators: [
			...typeConstraints.after.columnValidators,
			...typeConstraints.after_or_equal.columnValidators,
			...typeConstraints.before.columnValidators,
			...typeConstraints.before_or_equal.columnValidators,
			...typeConstraints.between.columnValidators,
			...typeConstraints.date_equals.columnValidators,
			...typeConstraints.date_format.columnValidators,
			...typeConstraints.timezone.columnValidators,
		],
		enforcedValidators: [...typeConstraints.date.columnValidators],
	},
	softDeletes: {
		columnParameters: [...typeConstraints.precision.columnParameters],
		columnModifiers: [
			...typeConstraints.useCurrent.columnModifiers,
			...typeConstraints._useCurrentOnUpdate.columnModifiers,
		],
		allowedValidators: [
			...typeConstraints.after.columnValidators,
			...typeConstraints.after_or_equal.columnValidators,
			...typeConstraints.before.columnValidators,
			...typeConstraints.before_or_equal.columnValidators,
			...typeConstraints.between.columnValidators,
			...typeConstraints.date_equals.columnValidators,
			...typeConstraints.date_format.columnValidators,
			...typeConstraints.timezone.columnValidators,
		],
		enforcedValidators: [...typeConstraints.date.columnValidators],
	},
	string: {
		columnParameters: [...typeConstraints.length.columnParameters],
		allowedValidators: [
			...typeConstraints.accepted.columnValidators,
			...typeConstraints.accepted_if.columnValidators,
			...typeConstraints.active_url.columnValidators,
			...typeConstraints.alpha.columnValidators,
			...typeConstraints.alpha_dash.columnValidators,
			...typeConstraints.alpha_num.columnValidators,
			...typeConstraints.array.columnValidators,
			...typeConstraints.ascii.columnValidators,
			...typeConstraints.between.columnValidators,
			...typeConstraints.contains.columnValidators,
			...typeConstraints.current_password.columnValidators,
			...typeConstraints.date.columnValidators,
			...typeConstraints.date_equals.columnValidators,
			...typeConstraints.date_format.columnValidators,
			...typeConstraints.decimal.columnValidators,
			...typeConstraints.declined.columnValidators,
			...typeConstraints.declined_if.columnValidators,
			...typeConstraints.digits.columnValidators,
			...typeConstraints.digits_between.columnValidators,
			...typeConstraints.doesnt_start_with.columnValidators,
			...typeConstraints.doesnt_end_with.columnValidators,
			...typeConstraints.email.columnValidators,
			...typeConstraints.ends_with.columnValidators,
			...typeConstraints.hex_color.columnValidators,
			...typeConstraints.integer.columnValidators,
			...typeConstraints.ip.columnValidators,
			...typeConstraints.ip4.columnValidators,
			...typeConstraints.ip6.columnValidators,
			...typeConstraints.lowercase.columnValidators,
			...typeConstraints.list.columnValidators,
			...typeConstraints.mac_address.columnValidators,
			...typeConstraints.max_digits.columnValidators,
			...typeConstraints.min_digits.columnValidators,
			...typeConstraints.multiple_of.columnValidators,
			...typeConstraints.numeric.columnValidators,
			...typeConstraints.size.columnValidators,
			...typeConstraints.starts_with.columnValidators,
			...typeConstraints.string.columnValidators,
			...typeConstraints.timezone.columnValidators,
			...typeConstraints.uppercase.columnValidators,
			...typeConstraints.url.columnValidators,
			...typeConstraints.ulid.columnValidators,
			...typeConstraints.uuid.columnValidators,
		],
	},
	text: {
		columnModifiers: [
			...typeConstraints._charset.columnModifiers,
			...typeConstraints.collation.columnModifiers,
		],
		allowedValidators: [
			...typeConstraints.active_url.columnValidators,
			...typeConstraints.alpha.columnValidators,
			...typeConstraints.alpha_dash.columnValidators,
			...typeConstraints.alpha_num.columnValidators,
			...typeConstraints.array.columnValidators,
			...typeConstraints.ascii.columnValidators,
			...typeConstraints.between.columnValidators,
			...typeConstraints.contains.columnValidators,
			...typeConstraints.current_password.columnValidators,
			...typeConstraints.date.columnValidators,
			...typeConstraints.date_equals.columnValidators,
			...typeConstraints.date_format.columnValidators,
			...typeConstraints.decimal.columnValidators,
			...typeConstraints.digits.columnValidators,
			...typeConstraints.digits_between.columnValidators,
			...typeConstraints.doesnt_start_with.columnValidators,
			...typeConstraints.doesnt_end_with.columnValidators,
			...typeConstraints.email.columnValidators,
			...typeConstraints.ends_with.columnValidators,
			...typeConstraints.hex_color.columnValidators,
			...typeConstraints.integer.columnValidators,
			...typeConstraints.ip.columnValidators,
			...typeConstraints.ip4.columnValidators,
			...typeConstraints.ip6.columnValidators,
			...typeConstraints.lowercase.columnValidators,
			...typeConstraints.list.columnValidators,
			...typeConstraints.mac_address.columnValidators,
			...typeConstraints.max_digits.columnValidators,
			...typeConstraints.min_digits.columnValidators,
			...typeConstraints.multiple_of.columnValidators,
			...typeConstraints.numeric.columnValidators,
			...typeConstraints.size.columnValidators,
			...typeConstraints.starts_with.columnValidators,
			...typeConstraints.string.columnValidators,
			...typeConstraints.timezone.columnValidators,
			...typeConstraints.uppercase.columnValidators,
			...typeConstraints.url.columnValidators,
			...typeConstraints.ulid.columnValidators,
			...typeConstraints.uuid.columnValidators,
		],
	},
	timeTz: {
		columnParameters: [...typeConstraints.precision.columnParameters],
		columnModifiers: [
			...typeConstraints.useCurrent.columnModifiers,
			...typeConstraints._useCurrentOnUpdate.columnModifiers,
		],
		allowedValidators: [
			...typeConstraints.after.columnValidators,
			...typeConstraints.after_or_equal.columnValidators,
			...typeConstraints.before.columnValidators,
			...typeConstraints.before_or_equal.columnValidators,
			...typeConstraints.between.columnValidators,
			...typeConstraints.date_equals.columnValidators,
			...typeConstraints.date_format.columnValidators,
			...typeConstraints.timezone.columnValidators,
		],
		enforcedValidators: [...typeConstraints.date.columnValidators],
	},
	time: {
		columnParameters: [...typeConstraints.precision.columnParameters],
		columnModifiers: [
			...typeConstraints.useCurrent.columnModifiers,
			...typeConstraints._useCurrentOnUpdate.columnModifiers,
		],
		allowedValidators: [
			...typeConstraints.after.columnValidators,
			...typeConstraints.after_or_equal.columnValidators,
			...typeConstraints.before.columnValidators,
			...typeConstraints.before_or_equal.columnValidators,
			...typeConstraints.between.columnValidators,
			...typeConstraints.date_equals.columnValidators,
			...typeConstraints.date_format.columnValidators,
			...typeConstraints.timezone.columnValidators,
		],
		enforcedValidators: [...typeConstraints.date.columnValidators],
	},
	timestampTz: {
		columnParameters: [...typeConstraints.precision.columnParameters],
		columnModifiers: [
			...typeConstraints.useCurrent.columnModifiers,
			...typeConstraints._useCurrentOnUpdate.columnModifiers,
		],
		allowedValidators: [
			...typeConstraints.after.columnValidators,
			...typeConstraints.after_or_equal.columnValidators,
			...typeConstraints.before.columnValidators,
			...typeConstraints.before_or_equal.columnValidators,
			...typeConstraints.between.columnValidators,
			...typeConstraints.date_equals.columnValidators,
			...typeConstraints.date_format.columnValidators,
			...typeConstraints.timezone.columnValidators,
		],
		enforcedValidators: [...typeConstraints.date.columnValidators],
	},
	timestamp: {
		columnParameters: [...typeConstraints.precision.columnParameters],
		columnModifiers: [
			...typeConstraints.useCurrent.columnModifiers,
			...typeConstraints._useCurrentOnUpdate.columnModifiers,
		],
		allowedValidators: [
			...typeConstraints.after.columnValidators,
			...typeConstraints.after_or_equal.columnValidators,
			...typeConstraints.before.columnValidators,
			...typeConstraints.before_or_equal.columnValidators,
			...typeConstraints.between.columnValidators,
			...typeConstraints.date_equals.columnValidators,
			...typeConstraints.date_format.columnValidators,
			...typeConstraints.timezone.columnValidators,
		],
		enforcedValidators: [...typeConstraints.date.columnValidators],
	},
	timestampsTz: {
		columnParameters: [...typeConstraints.precision.columnParameters],
		allowedValidators: [
			...typeConstraints.after.columnValidators,
			...typeConstraints.after_or_equal.columnValidators,
			...typeConstraints.before.columnValidators,
			...typeConstraints.before_or_equal.columnValidators,
			...typeConstraints.between.columnValidators,
			...typeConstraints.date_equals.columnValidators,
			...typeConstraints.date_format.columnValidators,
			...typeConstraints.timezone.columnValidators,
		],
		enforcedValidators: [...typeConstraints.date.columnValidators],
	},
	timestamps: {
		columnParameters: [...typeConstraints.precision.columnParameters],
		allowedValidators: [
			...typeConstraints.after.columnValidators,
			...typeConstraints.after_or_equal.columnValidators,
			...typeConstraints.before.columnValidators,
			...typeConstraints.before_or_equal.columnValidators,
			...typeConstraints.between.columnValidators,
			...typeConstraints.date_equals.columnValidators,
			...typeConstraints.date_format.columnValidators,
			...typeConstraints.timezone.columnValidators,
		],
		enforcedValidators: [...typeConstraints.date.columnValidators],
	},
	tinyInteger: {
		columnModifiers: [
			...typeConstraints.autoIncrement.columnModifiers,
			...typeConstraints._from.columnModifiers,
			...typeConstraints._unsigned.columnModifiers,
		],
		allowedValidators: [
			...typeConstraints.accepted.columnValidators,
			...typeConstraints.accepted_if.columnValidators,
			...typeConstraints.between.columnValidators,
			...typeConstraints.current_password.columnValidators,
			...typeConstraints.declined.columnValidators,
			...typeConstraints.declined_if.columnValidators,
			...typeConstraints.size.columnValidators,
		],
		enforcedValidators: [...typeConstraints.integer.columnValidators],
	},
	tinyText: {
		columnModifiers: [
			...typeConstraints._charset.columnModifiers,
			...typeConstraints.collation.columnModifiers,
		],
		allowedValidators: [
			...typeConstraints.accepted.columnValidators,
			...typeConstraints.accepted_if.columnValidators,
			...typeConstraints.active_url.columnValidators,
			...typeConstraints.alpha.columnValidators,
			...typeConstraints.alpha_dash.columnValidators,
			...typeConstraints.alpha_num.columnValidators,
			...typeConstraints.array.columnValidators,
			...typeConstraints.ascii.columnValidators,
			...typeConstraints.between.columnValidators,
			...typeConstraints.contains.columnValidators,
			...typeConstraints.current_password.columnValidators,
			...typeConstraints.date.columnValidators,
			...typeConstraints.date_equals.columnValidators,
			...typeConstraints.date_format.columnValidators,
			...typeConstraints.decimal.columnValidators,
			...typeConstraints.declined.columnValidators,
			...typeConstraints.declined_if.columnValidators,
			...typeConstraints.digits.columnValidators,
			...typeConstraints.digits_between.columnValidators,
			...typeConstraints.doesnt_start_with.columnValidators,
			...typeConstraints.doesnt_end_with.columnValidators,
			...typeConstraints.email.columnValidators,
			...typeConstraints.ends_with.columnValidators,
			...typeConstraints.hex_color.columnValidators,
			...typeConstraints.integer.columnValidators,
			...typeConstraints.ip.columnValidators,
			...typeConstraints.ip4.columnValidators,
			...typeConstraints.ip6.columnValidators,
			...typeConstraints.lowercase.columnValidators,
			...typeConstraints.list.columnValidators,
			...typeConstraints.mac_address.columnValidators,
			...typeConstraints.max_digits.columnValidators,
			...typeConstraints.min_digits.columnValidators,
			...typeConstraints.multiple_of.columnValidators,
			...typeConstraints.numeric.columnValidators,
			...typeConstraints.size.columnValidators,
			...typeConstraints.starts_with.columnValidators,
			...typeConstraints.string.columnValidators,
			...typeConstraints.timezone.columnValidators,
			...typeConstraints.uppercase.columnValidators,
			...typeConstraints.url.columnValidators,
			...typeConstraints.ulid.columnValidators,
			...typeConstraints.uuid.columnValidators,
		],
	},
	uuidMorphs: {},
	ulidMorphs: {},
	ulid: {
		enforcedValidators: [...typeConstraints.ulid.columnValidators],
	},
	uuid: {
		enforcedValidators: [...typeConstraints.uuid.columnValidators],
	},
	year: {
		columnModifiers: [
			...typeConstraints.useCurrent.columnModifiers,
			...typeConstraints._useCurrentOnUpdate.columnModifiers,
		],
		allowedValidators: [
			...typeConstraints.after.columnValidators,
			...typeConstraints.after_or_equal.columnValidators,
			...typeConstraints.before.columnValidators,
			...typeConstraints.before_or_equal.columnValidators,
			...typeConstraints.between.columnValidators,
			...typeConstraints.date_equals.columnValidators,
			...typeConstraints.date_format.columnValidators,
		],
		enforcedValidators: [...typeConstraints.date.columnValidators],
	},
}

export function validateColumnCompliance(val: EnrichedColumnSchema) {
	for (const constraint of val.typeConstraints ?? []) {
		if (
			constraint.type === 'parameter' &&
			!supportedConstraints[val.type].columnParameters?.some(
				(x) => x.columnParameterLabel === constraint.name,
			)
		) {
			throw new Error(
				`The column type '${val.type}' does not support the parameter '${constraint.name}'`,
			)
		}

		if (
			constraint.type === 'modifier' &&
			!supportedConstraints[val.type].columnModifiers?.some(
				(x) => x.columnModifier === constraint.name,
			) &&
			!Object.values(globalConstraints).some((x) =>
				x.columnModifiers.some((y) => y.columnModifier === constraint.name),
			)
		) {
			throw new Error(
				`The column type '${val.type}' does not support the modifier '${constraint.name}'`,
			)
		}

		if (
			constraint.type === 'validator' &&
			!supportedConstraints[val.type].allowedValidators?.some(
				(x) => x.columnValidator === constraint.name,
			) &&
			!supportedConstraints[val.type].enforcedValidators?.some(
				(x) => x.columnValidator === constraint.name,
			) &&
			!Object.values(globalConstraints).some((x) =>
				x.columnValidators.some((y) => y.columnValidator === constraint.name),
			)
		) {
			throw new Error(
				`The column type '${val.type}' does not support the validator '${constraint.name}'`,
			)
		}
	}

	for (const enforcedValidator of supportedConstraints[val.type].enforcedValidators ?? []) {
		if (
			!val.typeConstraints?.some(
				(constraint) =>
					constraint.type === 'validator' && constraint.name === enforcedValidator.columnValidator,
			)
		) {
			throw new Error(
				`The column type '${val.type}' must have the validator '${enforcedValidator.columnValidator}'`,
			)
		}
	}
}
