import {
	title,
	type Server as ServerType,
	type ServerList,
	type ServerStorePayload,
	type ServerUpdatePayload,
} from '@/interfaces/Server'
import ServersApi from '../api/ServersApi'
import ServerListState from '../states/ServerListState'
import { Model } from './Model'

export class ServerModel extends Model<
	ServersApi,
	ServerListState,
	ServerType,
	ServerList,
	ServerStorePayload,
	ServerUpdatePayload
> {
	api = new ServersApi()
	useListState = () => new ServerListState()
	title = title
}

const Server = new ServerModel()
export default Server
