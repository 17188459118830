<template>
	<Card class="apps">
		<template #title>
			<h4 class="apps__title">Apps</h4>
		</template>
		<template #content>
			<ApiTable
				:list-state="listState"
				@row-click="openDetails">
				<Column
					field="name"
					header="Name" />
				<Column header="">
					<template #body="slotProps">
						<Button
							severity="secondary"
							outlined
							rounded
							icon="fal fa-times"
							:loading="dissociateLoading === slotProps.data.id"
							@click.stop.prevent="detatch(slotProps.data)" />
					</template>
				</Column>
			</ApiTable>
		</template>
	</Card>
</template>

<script setup lang="ts">
import type { Server } from '@/interfaces/Server'
import { defineProps, onBeforeMount, ref } from 'vue'
import type { App } from '@/interfaces/App'
import AppModel from '@/helpers/models/App'
import ServerModel from '@/helpers/models/Server'
import Card from 'primevue/card'
import ApiTable from '@/components/Table/ApiTable.vue'
import Column from 'primevue/column'
import Button from 'primevue/button'
import { useRouter } from 'vue-router'

const emit = defineEmits(['start-loading', 'stop-loading'])

const props = defineProps<{
	serverId: Server['id']
}>()

const router = useRouter()
const dissociateLoading = ref(null as null | number | string)
const listLoading = ref(false)
const listState = AppModel.useListState()
listState.defaultParams = {
	per_page: 10,
	fromRelation: {
		model: 'App\\Models\\Server',
		id: props.serverId,
		relation: 'apps',
	},
}

onBeforeMount(() => {
	refresh()
})

async function refresh() {
	listLoading.value = true
	emit('start-loading')
	await listState.getList()
	listLoading.value = false
	emit('stop-loading')
}

async function detatch(item: App) {
	dissociateLoading.value = item.id
	emit('start-loading')
	await ServerModel.api.updateRelation(props.serverId, 'apps', {
		method: 'dissociate',
		params: [item.id],
	})
	dissociateLoading.value = null
	await refresh()
	emit('stop-loading')
}

function openDetails(item: { data: App }) {
	router.push({ name: 'apps-edit', params: { id: item.data.id } })
}
</script>

<style lang="scss" scoped>
.apps {
	width: 100%;
	max-width: 600px;

	&:deep(.p-card-title) {
		display: flex;
		align-items: center;
		gap: 10px;

		.apps__title {
			flex: 1;
			text-align: left;
		}
	}
}
</style>
