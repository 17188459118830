import type {
	Server,
	ServerList,
	ServerStorePayload,
	ServerUpdatePayload,
} from '@/interfaces/Server'
import Api from '@/helpers/api/Api'

export default class ServersApi extends Api<
	Server,
	ServerList,
	ServerStorePayload,
	ServerUpdatePayload
> {
	route = 'servers'
}
