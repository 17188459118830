import type { App, AppList, AppStorePayload, AppUpdatePayload } from '@/interfaces/App'
import Api from '@/helpers/api/Api'
import type { AxiosResponse } from 'axios'
import axios from 'axios'

const apiUrl = import.meta.env.VITE_API_URL

export default class AppsApi extends Api<App, AppList, AppStorePayload, AppUpdatePayload> {
	route = 'apps'

	async store(params: AppStorePayload): Promise<AxiosResponse<App>> {
		return await axios.post(`${apiUrl}${this.route}`, params, {
			timeout: 1200000,
		})
	}
}
