import type { LaravelPaginationResponse } from './Laravel'
import type { Title } from './Model'

export interface RepositoryConfigStorePayload {
	github_repository_url: string
	github_token: string
	app_id?: number
}

export interface RepositoryConfigUpdatePayload {
	github_repository_url: string
	github_token: string
	app_id?: number
}

export interface RepositoryConfig {
	id: string | number
	github_repository_url: string
	github_token: string
	app_id: number
	created_at: string
	updated_at: string
}

export interface RepositoryConfigList extends LaravelPaginationResponse<RepositoryConfig> {}

export const title: Title = 'github_repository_url'
