import type { RepositoryConfig, RepositoryConfigList } from '@/interfaces/RepositoryConfig'
import RepositoryConfigsApi from '../api/RepositoryConfigsApi'
import ListState from './ListState'

export default class RepositoryConfigListState extends ListState<
	RepositoryConfigsApi,
	RepositoryConfig,
	RepositoryConfigList
> {
	api = new RepositoryConfigsApi()
}
