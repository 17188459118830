import type { CodeDelivery, CodeDeliveryList } from '@/interfaces/CodeDelivery'
import CodeDeliveriesApi from '../api/CodeDeliveriesApi'
import ListState from './ListState'

export default class CodeDeliveryListState extends ListState<
	CodeDeliveriesApi,
	CodeDelivery,
	CodeDeliveryList
> {
	api = new CodeDeliveriesApi()
}
