import axios from 'axios'

export default class FontAwesomeApi {
	static search(searchString: string) {
		const query = `query { search(version: "6.0.0", query: "${searchString}", first: 50) { id, label, familyStylesByLicense { pro { prefix } } } }`
		return axios.post(
			'https://api.fontawesome.com',
			{
				query,
			},
			{
				withCredentials: false,
				withXSRFToken: false,
			},
		)
	}
}
