import type { Deployment } from './Deployment'
import type { LaravelPaginationResponse } from './Laravel'
import type { Title } from './Model'

export interface BuildStorePayload {
	app_id: number
	rebuild_from_build_id?: number
	draft: boolean
	app_schema?: string
	query?: string
	parent_build_id?: number
	version?: string
}

export interface BuildUpdatePayload {
	query?: string
	app_schema?: string
	app_id?: number
	draft?: boolean
	schema?: string
	parent_build_id?: number
	version?: string
}

export interface Build {
	id: string | number
	query: string
	app_schema: string
	app_id?: number
	head_sha?: string
	draft: boolean
	version: string | null
	created_at: string
	updated_at: string
	creator_id: number
	deployments?: Deployment[]
	parent_build?: Build | null
}

export interface BuildList extends LaravelPaginationResponse<Build> {}

export const title: Title = 'query'
