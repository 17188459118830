import {
	title,
	type App as AppType,
	type AppList,
	type AppStorePayload,
	type AppUpdatePayload,
} from '@/interfaces/App'
import AppsApi from '../api/AppsApi'
import AppListState from '../states/AppListState'
import { Model } from './Model'
import AppDetailsState from '../states/AppDetailsState'

export class AppModel extends Model<
	AppsApi,
	AppListState,
	AppType,
	AppList,
	AppStorePayload,
	AppUpdatePayload
> {
	api = new AppsApi()
	useListState = () => new AppListState()
	useDetailsState = () => new AppDetailsState()
	title = title
}

const App = new AppModel()
export default App
