import type { LaravelPaginationResponse } from './Laravel'
import type { Title } from './Model'
import type { Server } from './Server'
import type { User } from './User'

export interface DeploymentStorePayload {
	build_id?: Deployment['id']
}

export interface DeploymentUpdatePayload {
	status: string
	started_at: string
	finished_at: string
	server_id?: number
	build_id?: number
}

export interface Deployment {
	id: string | number
	status: string
	started_at: string
	finished_at: string
	server_id?: number
	build_id?: number
	created_at: string
	updated_at: string
	active: boolean
	creator?: User
	server?: Server
}

export interface DeploymentList extends LaravelPaginationResponse<Deployment> {}

export const title: Title = 'status'
