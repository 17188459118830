import type { Build, BuildList, BuildStorePayload, BuildUpdatePayload } from '@/interfaces/Build'
import Api from '@/helpers/api/Api'

export default class BuildsApi extends Api<
	Build,
	BuildList,
	BuildStorePayload,
	BuildUpdatePayload
> {
	route = 'builds'
}
